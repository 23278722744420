.form {
    width: 100%;
    margin-bottom: 5rem;
}

.form input {
    padding: 1rem;
    border: none;
    font-size: 1.5rem;
}

.err {
    color: darkred;
    font-size: 1rem;
    font-weight: 700;
}

.succ {
    color: rgb(79,91,88);
    font-weight: 800;
}
