@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&family=Milonga&family=UnifrakturMaguntia&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header_header__30zsP {
    position: relative;
    top: 0px;
    width: 100vw;
}

.header_locations__1WAnj, .header_contact__1seY5 {
    padding: 1rem;
}

.header_field__1q9_B {
    margin: 1rem;
    font-size: 1rem;
}

.header_field__1q9_B svg {
    margin-right: 0.3rem;
    color: grey;
}

.header_socials__2LlPX {
    padding-top: 0.5rem;
}

.header_socials__2LlPX a {
    margin: 2rem;
    opacity: 0.8;
}

.footer_footer__5IoHz {
    position: fixed;
    bottom: 0;
    width: 100vw;
    background-color: rgba(237, 134, 9, 0.2);
    height: 2.1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1rem;
}
.splash_form__w0yFz {
    width: 100%;
    margin-bottom: 5rem;
}

.splash_form__w0yFz input {
    padding: 1rem;
    border: none;
    font-size: 1.5rem;
}

.splash_err__3pas7 {
    color: darkred;
    font-size: 1rem;
    font-weight: 700;
}

.splash_succ__2Bpqw {
    color: rgb(79,91,88);
    font-weight: 800;
}

/* font-family: 'Josefin Sans', sans-serif;
font-family: 'Milonga', cursive;
font-family: 'UnifrakturMaguntia', cursive; */

:root {
  --bg-color: rgb(244,240,239); 
  --text-color: rgb(11,20,43); 
}

body { 
  background-color: rgb(244,240,239); 
  background-color: var(--bg-color); 
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Josefin Sans', sans-serif;
  color: rgb(11,20,43);
  color: var(--text-color); 
  font-size: 1.5em;
  height: 100vh;
  width: 100vw;
  /* padding: 1rem; */
}

form input {
  font-family: 'Josefin Sans', sans-serif;
}

h1 {
  font-size: 5rem;
  font-family: 'Milonga', cursive;
  letter-spacing: -0.3rem;
}

cap {
  letter-spacing: -1.3rem;
  font-size: 8rem;
  position: relative;
  top: 1.7rem;
  /* font-family: 'UnifrakturMaguntia', cursive; */
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  font-weight: bolder;
}

section {
  line-height: 2rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media(max-width: 700px), (max-height: 590px){
  h3 {
    font-size: 1.5rem;
  }

  #fields {
    font-size: 1rem;
  }

  #location {
    font-size: 1.5rem;
  }

  #contact {
    font-size: 0.7em;
  }

  h1 {
    font-size: 4rem;
    letter-spacing: -0.35rem;
  }

  cap {
    letter-spacing: -1.2rem;
    font-size: 6rem;
    position: relative;
    top: 1.1rem;
  }

  #contact section {
    line-height: 1.5rem;
  }
}

@media(max-height: 590px){
  h1 {
    margin: 0;
  }

  #contact {
    display: none;
  }
}

@media(max-width: 700px){
  #fields {
    font-size: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 1rem;
  }

  #fields span {
    margin-top: -1.7rem;
  }

  h3 {
    padding: 0 3rem;
    margin-top: -2rem;
  }
}
